import { IPasswordSignInData } from "@/types/auth";
import APIService from "./api.service";

export class AuthService extends APIService {
  constructor() {
    super("https://aproof-demo-api.diamanthq.dev");
  }

  async check(): Promise<boolean> {
    return this.get(`/auth/check`)
      .then((response) => {
        return response.status == 200;
      })
      .catch((error) => {
        throw error?.response;
      });
  }

  async loginEmail(data: IPasswordSignInData): Promise<any> {
    return this.post(`/auth/login/email`, data)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        throw error?.response;
      });
  }

  async logout(): Promise<boolean> {
    return this.get(`/auth/logout`)
      .then((response) => {
        return response.status == 200;
      })
      .catch((error) => {
        throw error?.response;
      });
  }
}
