import { useMutation, useQueryClient } from "@tanstack/react-query";
import { z } from "zod";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { Button } from "@/components/ui/button";
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { Textarea } from "@/components/ui/textarea";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import { CalendarIcon } from "@radix-ui/react-icons";
import { Calendar } from "@/components/ui/calendar";
import { cn } from "@/lib/utils";
import { format } from "date-fns";
import { TaskService } from "@/services/tasks.service";
import { useNavigate } from "react-router-dom";
import { ICreateTask } from "@/types/task";

const createTaskFormSchema = z.object({
  title: z
    .string()
    .min(10, { message: "Title must be at least 5 characters." })
    .max(1000, { message: "Title must not be longer than 1000 characters." }),

  description: z.string().min(10).max(10000),
  goal: z.string().min(10).max(10000),

  start_date: z.date({
    required_error: "A start date is required.",
  }),
  end_date: z.date({
    required_error: "A end date is required.",
  }),
});

type CreateTaskFormValues = z.infer<typeof createTaskFormSchema>;

// This can come from your database or API.
const defaultValues: Partial<CreateTaskFormValues> = {
  title: "Nice title, good description",
  description: "Let me explain what I'm going to do",
  goal: "What I'm would like to achieve",
};

const taskService = new TaskService();

export default function TaskCreatePage() {
  const navigate = useNavigate();

  const form = useForm<CreateTaskFormValues>({
    resolver: zodResolver(createTaskFormSchema),
    defaultValues,
    mode: "onChange",
  });

  const queryClient = useQueryClient();

  const createTaskMutation = useMutation({
    mutationFn: async (data: ICreateTask) => {
      return taskService.createTask(data);
    },
    onSuccess: (data) => {
      console.warn("onSuccess", JSON.stringify(data));
      const taskId = data.task_id;
      queryClient.setQueriesData({ queryKey: ["tasks", taskId] }, data);
      queryClient.invalidateQueries({ queryKey: ["tasks"] });

      navigate(`https://aproof-demo-app.diamanthq.dev/tasks/${taskId}`);
    },
  });

  function onSubmit(data: CreateTaskFormValues) {
    console.warn("onSubmit", data);

    createTaskMutation.mutate({
      title: data.title,
      description: data.description,
      goal: data.goal,
    });
  }

  return (
    <div className="space-y-6">
      <div>
        <h3 className="text-lg font-medium">Create task</h3>
      </div>
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-8">
          <FormField
            control={form.control}
            name="title"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Title</FormLabel>
                <FormDescription>Title of your task</FormDescription>
                <FormControl>
                  <Input placeholder={defaultValues.title} {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="description"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Description</FormLabel>
                <FormDescription>
                  You can <span>@mention</span> other users and organizations to
                  link to them.
                </FormDescription>
                <FormControl>
                  <Textarea
                    placeholder={defaultValues.description}
                    className="resize-none"
                    {...field}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="goal"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Goal</FormLabel>
                <FormDescription>
                  You can <span>@mention</span> other users and organizations to
                  link to them.
                </FormDescription>
                <FormControl>
                  <Textarea
                    placeholder={defaultValues.goal}
                    className="resize-none"
                    {...field}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="start_date"
            render={({ field }) => (
              <FormItem className="flex flex-col">
                <FormLabel>Start date</FormLabel>
                <FormDescription>
                  Your date of birth is used to calculate your age.
                </FormDescription>
                <Popover>
                  <PopoverTrigger asChild>
                    <FormControl>
                      <Button
                        variant={"outline"}
                        className={cn(
                          "w-[240px] pl-3 text-left font-normal",
                          !field.value && "text-muted-foreground",
                        )}
                      >
                        {field.value ? (
                          format(field.value, "PPP")
                        ) : (
                          <span>Pick a date</span>
                        )}
                        <CalendarIcon className="ml-auto h-4 w-4 opacity-50" />
                      </Button>
                    </FormControl>
                  </PopoverTrigger>
                  <PopoverContent className="w-auto p-0" align="start">
                    <Calendar
                      mode="single"
                      selected={field.value}
                      onSelect={field.onChange}
                      disabled={(date) =>
                        date < new Date() || date > new Date("2100-01-01")
                      }
                      initialFocus
                    />
                  </PopoverContent>
                </Popover>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="end_date"
            render={({ field }) => (
              <FormItem className="flex flex-col">
                <FormLabel>End date</FormLabel>
                <FormDescription>
                  Your date of birth is used to calculate your age.
                </FormDescription>
                <Popover>
                  <PopoverTrigger asChild>
                    <FormControl>
                      <Button
                        variant={"outline"}
                        className={cn(
                          "w-[240px] pl-3 text-left font-normal",
                          !field.value && "text-muted-foreground",
                        )}
                      >
                        {field.value ? (
                          format(field.value, "PPP")
                        ) : (
                          <span>Pick a date</span>
                        )}
                        <CalendarIcon className="ml-auto h-4 w-4 opacity-50" />
                      </Button>
                    </FormControl>
                  </PopoverTrigger>
                  <PopoverContent className="w-auto p-0" align="start">
                    <Calendar
                      mode="single"
                      selected={field.value}
                      onSelect={field.onChange}
                      disabled={(date) =>
                        date < new Date() || date > new Date("2100-01-01")
                      }
                      initialFocus
                    />
                  </PopoverContent>
                </Popover>
                <FormMessage />
              </FormItem>
            )}
          />
          <Button type="submit">Create task</Button>
        </form>
      </Form>
    </div>
  );
}
