import { ICreateTask } from "@/types/task";
import APIService from "./api.service";

export class TaskService extends APIService {
  constructor() {
    super("https://aproof-demo-api.diamanthq.dev/space/123");
  }

  async getTasks(): Promise<any> {
    return this.get(`/tasks`)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error?.response;
      });
  }

  async getTask(taskId: string): Promise<any> {
    return this.get(`/tasks/${taskId}`)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error?.response;
      });
  }

  async createTask(data: ICreateTask): Promise<any> {
    return this.post(`/tasks/create`, data)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error?.response;
      });
  }
}
