import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Separator } from "./ui/separator";
import { Badge } from "./ui/badge";

type TaskDates = {
  createdAt: string;
  updatedAt: string;
  startAt: string;
  finishAt: string;
  duration: string;
  resolvedAt: string;
};

export function TaskDates(params: TaskDates) {
  return (
    <Card>
      <CardHeader>
        <CardTitle>Dates</CardTitle>
      </CardHeader>
      <Separator className="my-4" />
      <CardContent>
        <div className="space-y-4">
          <h4 className="text-sm font-medium">Created</h4>
          <div className="grid gap-6">
            <div className="flex items-center justify-between space-x-4">
              <div className="flex items-center space-x-4">
                <Badge key={params.createdAt} variant="outline">
                  {params.createdAt}
                </Badge>
              </div>
            </div>
          </div>
        </div>
      </CardContent>
    </Card>
  );
}
