import { TaskDates } from "@/components/task-dates";
import { TaskDetails } from "@/components/task-description";
import { TaskInfo } from "@/components/task-info";
import { TaskMembers } from "@/components/task-members";
import { cn } from "@/lib/utils";
import { TaskService } from "@/services/tasks.service";
import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";

const taskService = new TaskService();

export default function TaskPage() {
  const { taskId } = useParams();

  const taskQuery = useQuery({
    queryKey: ["tasks", taskId],
    queryFn: () => taskService.getTask(taskId!),
    retry: false,
  });

  if (taskQuery.isLoading) return "Loading...";
  if (taskQuery.error) {
    return "An error has occurred: " + JSON.stringify(taskQuery);
  }

  const task = taskQuery.data;

  return (
    <>
      <div className="hidden items-start justify-center gap-6 rounded-lg p-8 md:grid sm:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2">
        <div className="col-span-2 grid items-start gap-6 lg:col-span-1">
          <TaskPageContainer>
            <TaskInfo id={task.id} title={task.title} status={task.status} />
          </TaskPageContainer>
          <TaskPageContainer>
            <TaskDetails description={task.description} goal={""} />
          </TaskPageContainer>
        </div>
        <div className="col-span-2 grid items-start gap-6 lg:col-span-1">
          <TaskPageContainer>
            <TaskDates
              createdAt={task.createdAt}
              updatedAt={task.updatedAt}
              startAt={task.startAt}
              finishAt={task.finishAt}
              duration={task.duration}
              resolvedAt={task.resolvedAt}
            />
          </TaskPageContainer>
          <TaskPageContainer>
            <TaskMembers
              createdBy={task.createdBy}
              assignees={task.assignees}
              watchers={task.watchers}
            />
          </TaskPageContainer>
        </div>
      </div>
    </>
  );
}

function TaskPageContainer({
  className,
  ...props
}: React.HTMLAttributes<HTMLDivElement>) {
  return (
    <div
      className={cn(
        "flex items-center justify-center [&>div]:w-full",
        className,
      )}
      {...props}
    />
  );
}
