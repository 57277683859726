import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Separator } from "./ui/separator";

type TaskDetails = {
  description: string;
  goal: string;
};

export function TaskDetails(params: TaskDetails) {
  return (
    <Card>
      <CardHeader>
        <CardTitle>Details</CardTitle>
      </CardHeader>
      <Separator className="my-4" />
      <CardContent>
        <div className="space-y-4">
          <h4 className="text-sm font-medium">Created</h4>
          <div className="grid gap-6">
            <div className="flex items-center justify-between space-x-4">
              <div className="flex items-center space-x-4">
                {params.description}
              </div>
            </div>
          </div>
        </div>
      </CardContent>
    </Card>
  );
}
