import axios, { AxiosInstance, AxiosResponse } from "axios";

abstract class APIService {
  protected baseURL: string;
  protected headers: any = {};
  protected client: AxiosInstance;

  constructor(_baseURL: string) {
    this.baseURL = _baseURL;
    this.client = axios.create({
      baseURL: _baseURL,
      withCredentials: true,
      headers: {
        "Access-Control-Allow-Credentials": true,
        "Access-Control-Allow-Origin": [
          "aproof-demo-api.diamanthq.dev",
          "aproof-demo-app.diamanthq.dev",
        ],
        "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
      },
    });
  }

  get(url: string, config = {}): Promise<AxiosResponse> {
    return this.client.get(url, {
      method: "get",
      ...config,
    });
  }

  post(url: string, data = {}, config = {}): Promise<any> {
    return axios({
      method: "post",
      url: this.baseURL + url,
      data: data,
      ...config,
    });
  }

  put(url: string, data = {}, config = {}): Promise<any> {
    return axios({
      method: "put",
      url: this.baseURL + url,
      data: data,
      ...config,
    });
  }

  patch(url: string, data = {}, config = {}): Promise<any> {
    return axios({
      method: "patch",
      url: this.baseURL + url,
      data: data,
      ...config,
    });
  }

  delete(url: string, data?: any, config = {}): Promise<any> {
    return axios({
      method: "delete",
      url: this.baseURL + url,
      data: data,
      ...config,
    });
  }

  mediaUpload(url: string, data = {}, config = {}): Promise<any> {
    return axios({
      method: "post",
      url: this.baseURL + url,
      data: data,
      ...config,
    });
  }

  request(config = {}) {
    return axios(config);
  }
}

export default APIService;
