import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Separator } from "./ui/separator";
import { Badge } from "./ui/badge";
import { Member } from "@/types/member";

type TaskMembers = {
  createdBy: Member;
  assignees: Array<Member>;
  watchers: Array<Member>;
};

export function TaskMembers(params: TaskMembers) {
  return (
    <Card>
      <CardHeader>
        <CardTitle>Members</CardTitle>
      </CardHeader>
      <Separator className="my-4" />
      <CardContent>
        <div className="space-y-4">
          <h4 className="text-sm font-medium">Created</h4>
          <div className="grid gap-6">
            <div className="items-center justify-between space-x-4">
              <div className="items-center space-x-4">
                <Badge key={params.createdBy.id} variant="outline">
                  {params.createdBy.name}
                </Badge>
              </div>
            </div>
            <h4 className="text-sm font-medium">Assignee(s)</h4>
            <div className="items-center justify-between space-x-4">
              {params.assignees.map((assignee) => {
                return (
                  <>
                    <div className="items-center gap-2">
                      <Badge key={assignee.id} variant="outline">
                        {assignee.name}
                      </Badge>
                    </div>
                  </>
                );
              })}
            </div>
            <h4 className="text-sm font-medium">Watcher(s)</h4>
            <div className="items-center justify-between space-x-4">
              {params.watchers.map((watcher) => {
                return (
                  <>
                    <div className="items-center gap-2">
                      <Badge key={watcher.id} variant="outline">
                        {watcher.name}
                      </Badge>
                    </div>
                  </>
                );
              })}
            </div>
          </div>
        </div>
      </CardContent>
    </Card>
  );
}
