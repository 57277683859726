import "@/App.css";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";

import AboutPage from "@/pages/About";
import HomePage from "@/pages/Home";
import LoginPage from "@/pages/Login";
import TaskCreatePage from "@/pages/TaskCreate";
import TaskPage from "@/pages/Task";
import TasksPage from "@/pages/Tasks";
import { useRoutes } from "react-router-dom";
import Navbar from "./components/navbar";

const queryClient = new QueryClient({
  defaultOptions: { queries: { staleTime: 1000 * 60 } },
});

function AppRoutes() {
  const element = useRoutes([
    { path: "/", element: <HomePage /> },
    { path: "/login", element: <LoginPage /> },
    {
      path: "/tasks",
      children: [
        { index: true, element: <TasksPage /> },
        { path: ":taskId", element: <TaskPage /> },
        { path: "create", element: <TaskCreatePage /> },
      ],
    },
    { path: "/about", element: <AboutPage /> },
    {
      path: "*",
      element: (
        <>
          <h1>Not found</h1>
        </>
      ),
    },
  ]);
  return element;
}

export default function App() {
  // const [user, setUser] = useState(null);
  // const handleLogin = () => setUser({ id: "1", name: "robin" });
  // const handleLogout = () => setUser(null);

  return (
    <>
      <Navbar />
      <QueryClientProvider client={queryClient}>
        <AppRoutes />
        <ReactQueryDevtools />
      </QueryClientProvider>
    </>
  );
}
