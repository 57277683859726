import { useQuery } from "@tanstack/react-query";
import { Button } from "@/components/ui/button";
import { TaskService } from "@/services/tasks.service";
import { Link } from "react-router-dom";
import { DataTable } from "@/components/data-table-components/data-table";
import { columns } from "@/components/data-table-components/columns";

const taskService = new TaskService();

export default function TasksPage() {
  const tasksQuery = useQuery({
    queryKey: ["tasks"],
    queryFn: () => {
      return taskService.getTasks();
    },
  });

  if (tasksQuery.isLoading) return "Loading...";
  if (tasksQuery.error) {
    return "An error has occurred: " + JSON.stringify(tasksQuery.error);
  }

  const rows = tasksQuery.data["data"];

  return (
    <>
      <Link to="/tasks/create">
        <Button>Create task</Button>
      </Link>
      <div className="hidden h-full flex-1 flex-col space-y-8 p-8 md:flex">
        <div className="items-center justify-between space-y-2">
          <DataTable columns={columns} data={rows} />
        </div>
      </div>
    </>
  );
}
