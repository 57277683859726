import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Separator } from "./ui/separator";
import { Badge } from "./ui/badge";

type TaskInfo = {
  id: string;
  title: string;
  status: string;
};

export function TaskInfo(params: TaskInfo) {
  return (
    <Card>
      <CardHeader>
        <CardTitle>Info</CardTitle>
      </CardHeader>
      <Separator className="my-4" />
      <CardContent>
        <div className="space-y-4">
          <h4 className="text-sm font-medium">ID</h4>
          <Badge key={params.id} variant="default">
            {params.id}
          </Badge>
        </div>
        <div className="space-y-4">
          <h4 className="text-sm font-medium">Created</h4>
          <Badge key={params.status} variant="default">
            {params.status}
          </Badge>
        </div>
        <div className="space-y-4">
          <h4 className="text-sm font-medium">Created</h4>
          <Badge key={params.status} variant="default">
            {params.status}
          </Badge>
        </div>
      </CardContent>
    </Card>
  );
}
